<template>
  <div class="content">
    <h1>lee.dateCollect.dateDiff(beginDate,endDate)</h1>
    <p class="cm-variable">两个日期差</p>
    <h3>参数</h3>
    <ul>
      <li><code>beginDate</code><em>(Date)</em>:开始日期</li>
      <li><code>endDate</code><em>(Date)</em>:结束日期</li>
    </ul>
    <h3>例子</h3>
    <div class="jscode" style="background: #f6f8fa">
      <div class="jscontent" v-highlight>
        <pre><code class="language-javascript">let beginDate = '2022-10-31'
let endDate = '2025-10-31'
let dateDiff = lee.dateCollect.dateDiff(beginDate,endDate)
console.log(dateDiff)</code></pre>
      </div>
    </div>
  </div>
</template>
<script>
let lee = window.lee
let beginDate = '2022-10-31'
let endDate = '2025-10-31'
let dateDiff = lee.dateCollect.dateDiff(beginDate,endDate)
console.log(dateDiff)
</script>
